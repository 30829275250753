<template lang="pug">
div(v-if='totalReviews > 0' class='flex relative font-bold')
  button(
    type='button'
    aria-label='Show Reviews'
    class='z-10 absolute top-0 bottom-0 right-0 left-0 cursor-pointer'
    @click='scrollToReviews'
  )
  Stars(:average-rating='averageRating' :total-reviews='totalReviews' :show-average-rating='showAverageRating')
</template>

<script setup lang="ts">
const { $scrollTo } = useNuxtApp()
const { totalReviews, averageRating } = defineProps<{
  totalReviews: ProductLine['totalReviews']
  averageRating: ProductLine['averageRating']
  showAverageRating: ProductLine['showAverageRating']
}>()

function scrollToReviews() {
  $scrollTo('#scrollTarget-reviews')
}
</script>
