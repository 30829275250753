<template lang="pug">
div(
  v-if='!showConsentWarning'
  class='customer-photos yotpo yotpo-pictures-widget [&_.yotpo-pictures-gallery-header-wrapper]:!hidden [&_.yotpo-lightbox-product-button]:!hidden'
  :data-gallery-id='galleryId'
  :data-product-id='yotpoId'
)
div(v-else class='w-full h-full flex items-center justify-center p-4 bg-gray-lighter')
  div(class='text-center')
    | Customer Photos are disabled because you haven't consented to non-essential cookies.
    br
    BaseButton(class='mt-8' label='Consent' to='KETCH' size='SMALL') Update Preferences
</template>

<script setup lang="ts">
const { $yotpo, $ketch } = useNuxtApp()
const { showConsentWarning } = useYotpo()
const { isIntegrationEnabled } = useUtils()

onMounted(() => {
  if (!isIntegrationEnabled('ketch')) refresh()
  else {
    $ketch.onConsentResolved(() => {
      if ($ketch.hasConsent.value) refresh()
    })
  }
})

function refresh() {
  $yotpo.addCallback(() => {
    try {
      // Gallery is still on V2 and must refresh the old way.
      const api = $yotpo.getApi()
      setTimeout(function () {
        api.refreshWidgets()
      }, 2000)
    } catch (err) {
      // do nothing, we hate you yotpo..
    }
  })
  $yotpo.load() // makes sure the callback is fired after yotpo is loaded
}

const { yotpoId, galleryId } = defineProps<{
  yotpoId: string
  galleryId: string
}>()
</script>
