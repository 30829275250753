<template lang="pug">
div(class='lg:grid lg:grid-cols-2 lg:gap-16')
  ReadMore
    div(class='space-y-6')
      h2(class='mt-0') Warranty
      p(class='font-bold')
        InlineHtml(v-if='data.warranty.title' :text='data.warranty.title')
      InlineHtml(v-if='data.warranty.content' wrapper='div' :text='data.warranty.content')
      ProductExtraInfoFileList(v-if='data.warranty.files' :files='data.warranty.files')
      ProductExtraInfoVideoList(
        v-if='data.warranty.videos'
        :videos='data.warranty.videos'
        section='warranty'
        :grid-cols='gridCols'
      )

  div(
    v-if='(data.specSheet && data.specSheet.length > 1) || data.guarantee'
    class='space-y-6 mt-12 lg:border-l lg:border-l-gray-light lg:pl-16 lg:mt-0'
  )
    h2(class='mt-0') Specifications
    template(v-for='spec in data.specSheet' :key='spec.key')
      div(class='grid grid-cols-3 space-x-6')
        InlineHtml(:text='spec.key' class='font-bold col-span-1')
        InlineHtml(:text='spec.value' class='col-span-2')
    div(class='prop-65 relative inline-block font-bold mt-4 mb-3 group')
      | California Residents:&nbsp;
      span(class='font-normal underline cursor-pointer') Prop 65 Warning
      div(class='absolute left-0 right-0 bottom-full hidden group-hover:block')
        img(src='/images/prop65.svg' width='271' height='56' alt='May cause cancer' loading='lazy')
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()
const { data } = defineProps<{ data: ProductLine }>()

const gridCols = computed(() => {
  if ($device.value.isMobile) return 1
  if (!$device.value.isDesktop) return 2
  return data.warranty?.videos && data.warranty.videos.length > 2 ? 4 : 2
})
</script>
