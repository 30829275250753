export const useProductStore = defineStore('Product', () => {
  const data: Ref<ProductLine | null> = ref(null)
  const { $timing } = useNuxtApp()

  const { getApiUrl } = useUrls()

  async function init(productLineSlug: string, skuSlug?: string) {
    let slug = productLineSlug
    if (skuSlug) slug += `/${skuSlug}`

    if (data.value && data.value.refreshKey === slug) return

    const url = getApiUrl('product') + slug
    $timing.start('product-api')
    const resp = (await $fetch(url)) as ProductLine
    $timing.end('product-api')

    data.value = { ...resp, refreshKey: slug }
  }

  return { data, init }
})
