<template lang="pug">
div
  div(v-if='data.specifications')
    h2 Details
    div(v-if='data.specifications.content')
      InlineHtml(:text='data.specifications.content')
    ProductExtraInfoFileList(v-if='data.specifications.files' :files='data.specifications.files')
    ProductExtraInfoVideoList(
      v-if='data.specifications.videos'
      :videos='data.specifications.videos'
      section='specs'
      :grid-cols='gridColsSpec'
    )
  div(v-if='data.howItWorks')
    h2 How It Works
    ProductExtraInfoImageList(:data='data.howItWorks')
  div(v-if='data.additionalFeatures')
    h2 Additional Features
    ProductExtraInfoImageList(:data='data.additionalFeatures')
  div(v-if='data.faq')
    h2 Frequently Asked Questions
    div(v-if='data.faq.content')
      InlineHtml(:text='data.faq.content')
    ProductExtraInfoFileList(v-if='data.faq.files' :files='data.faq.files')
    ProductExtraInfoVideoList(v-if='data.faq.videos' :videos='data.faq.videos' section='faq' :grid-cols='gridColsFaq')
</template>

<script setup lang="ts">
// NOTE: Nuxt 3 - Haven't found any plines to test Additional Features or FAQ
// Specifications - /p/retraxpro-xr-tonneau-cover
const { data } = defineProps<{ data: ProductLine }>()
const { $device } = useNuxtApp()

const gridColsSpec = computed(() => {
  if ($device.value.isMobile) return 1
  if (!$device.value.isDesktop) return 2
  return data.specifications?.videos && data.specifications.videos.length > 2 ? 4 : 2
})

const gridColsFaq = computed(() => {
  if ($device.value.isMobile) return 1
  if (!$device.value.isDesktop) return 2
  return data.faq?.videos && data.faq.videos.length > 2 ? 4 : 2
})
</script>
