<template lang="pug">
div(v-if='data' class='w-full space-y-6 mt-6')
  div(v-if='data.promo' class='relative w-full')
    strong(v-if='data.promo.title' class='uppercase block text-danger') {{ data.promo.title }} #[span(v-if='data.promo.title && data.promo.content')]
    |
    | {{ data.promo.content }}
    |
    span(v-if='data.promo && data.promo.file' class='block') #[a(:href='getFileUrl(data.promo.file.folder, data.promo.file.filename)' target='_blank') See More].

  div(
    v-if='data.discount && !$sitewideConfig.config.nonTransactionalEnabled'
    class='relative font-bold uppercase w-full text-danger'
  )
    strong Now {{ data.discount.amountFormatted }} off
    |
    | - hurry,
    span(v-if='data.discount.expiresFormatted')
      |
      | offer ends {{ data.discount.expiresFormatted }}!
    span(v-else)
      |
      | limited time offer!

  div(v-if='isOnlineExclusive' class='flex items-center')
    img(src='/images/the-r.svg' alt='' width='32' height='32')
    div(class='relative pl-4 w-full') Online Exclusive

  div(v-if='data.isMadeInAmerica')
    div(class='flex items-center')
      img(src='/images/us-flag.svg' alt='Made in the USA' width='32' height='21')
      div(class='relative pl-4 w-full') Made in the USA

  div(v-if='!isGroupProduct' class='relative')
    ClientOnly
      ProductVehicleSelection(
        :guaranteed-fit='$sitewideConfig.config.guaranteedFitEnabled ? data.guaranteedFit : undefined'
      )

  template(v-if='!data.isOutOfStock && !$sitewideConfig.config.nonTransactionalEnabled && (data.mpn || data.sku)')
    ProductShippingDisplay(:product='data' show-icon)
      template(#default='{ shipMethod }')
        ClientOnly
          Installernet(
            v-if='shipMethod === "EXPEDITED" && isIntegrationEnabled("geolocation") && isIntegrationEnabled("installernet")'
            mode='ADD_TO_CART'
            :item='data'
          )

  div(v-if='data.mpn || data.sku' class='font-normal')
    span(class='text-left text-sm whitespace-nowrap uppercase pr-1') Part #:
    span {{ data.mpn || data.sku }}

  div(v-if='data.upc' class='font-normal')
    span(class='text-left text-sm whitespace-nowrap uppercase pr-1') UPC:
    span {{ data.upc }}

  template(v-if='isProductPage')
    template(v-for='attr in data.attributes' :key='attr.key')
      div(class='font-normal')
        span(class='text-left text-sm whitespace-nowrap uppercase pr-1') {{ attr.key }}:
        span {{ attr.value }}
</template>

<script setup lang="ts">
const { data, isGroupProduct = false } = defineProps<{ data: ProductLine | GroupProduct; isGroupProduct?: boolean }>()
const { $sitewideConfig } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()

const isProductPage = useRoute().params.skuSlug

const isOnlineExclusive = computed(() => {
  // Only show on Gator and Ionic
  return data.brand?.name === 'Gator' || data.brand?.name === 'Ionic'
})
</script>
