<template lang="pug">
div(class='space-y-12')
  template(
    v-if='data.featureVideo || data.featureBlocks || (data.features && data.features.content) || data.description'
  )
    div(v-if='data.featureBlocks' class='py-8 md:py-12')
      ProductExtraInfoItemCarousel(:data='data.featureBlocks')
    div(v-if='data.features && data.features.content && !data.hasOverriddenFeatureBullets')
      h2 Features
      InlineHtml(:text='data.features.content' wrapper='div')
    div(v-if='data.featureVideo' ref='featureVideo')
      VideoPlayer(:video='data.featureVideo')
    div(
      v-if='data.description'
      class='[&_table]:mb-[15px] [&_table]:mt-[15px] [&_table]:bg-white [&_table]:border [&_table]:border-black [&_table]:p-[6px] [&_th]:bg-[#eee] [&_th]:border [&_th]:border-black [&_th]:p-[6px] [&_td]:bg-white [&_td]:border [&_td]:border-black [&_td]:p-[6px]'
    )
      h2(id='scrollTarget-description') Description
      ReadMore
        div(v-if='data.description.main')
          InlineHtml(:text='data.description.main' wrapper='div')
        div(v-if='data.description.extended')
          InlineHtml(:text='data.description.extended' wrapper='div')

  div(v-if='data.orderNotes')
    h3 Order Notes
    InlineHtml(:text='data.orderNotes' wrapper='div')

  div(v-if='data.features && (data.features.files || data.features.videos)')
    h3 More Information
    ProductExtraInfoFileList(v-if='data.features && data.features.files' :files='data.features.files')
    ProductExtraInfoVideoList(
      v-if='data.features && data.features.videos'
      :videos='data.features.videos'
      section='video-list'
      :grid-cols='gridCols'
    )
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()
const { data } = defineProps<{ data: ProductLine }>()

const gridCols = computed(() => {
  if ($device.value.isMobile) return 1
  if (!$device.value.isDesktop) return 2
  return data.features?.videos && data.features.videos.length > 2 ? 4 : 2
})
</script>
