<template lang="pug">
div(class='relative')
  div(ref='content' :class='!expanded && !disabled ? "gradient overflow-y-hidden" : ""' :style='styles')
    slot
  div(v-if='!disabled' class='w-full bg-white' :class='!expanded ? "absolute bottom-0 " : ""')
    button(v-if='!expanded' type='button' class='hover:underline' @click='expanded = true')
      span(class='flex') View more
        img(src='@/assets/arrow.svg' class='w-3 ml-2' height='12' width='12' alt='Previous Page')
    button(v-else type='button' class='relative mt-3 hover:underline' @click='expanded = false')
      span(class='flex') View less
        img(src='@/assets/arrow.svg' class='w-3 ml-2 transform rotate-180' height='12' width='12' alt='Previous Page')
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()
// Look at the height of the content to determine if it's beyond the maxHeight
const content = ref<HTMLElement>()
// This feature is for Mobile only so we need a way to disable it
const disabled = ref(false)
// Is it currently expanded
const expanded = ref(false)

const { maxHeight = 450 } = defineProps<{
  maxHeight?: number
}>()

onMounted(() => {
  toggleDisabled()
})

const toggleDisabled = () => {
  // Always disabled on Desktop
  if ($device.value.isDesktop) {
    disabled.value = true
    return
  }

  // Disable only if the content is smaller than max height
  if (content.value?.scrollHeight < maxHeight) {
    disabled.value = true
  } else disabled.value = false
}

// Toggle when the window size changes
watch($device.value, () => {
  // Check if we should be disbaled or not
  nextTick(() => {
    toggleDisabled()
  })
})

const styles = computed(() => {
  if (expanded.value || disabled.value) return {}
  return {
    'max-height': `${maxHeight}px`,
  }
})
</script>

<style scoped>
/* Gradient will start 70% of the way down */
.gradient {
  mask-image: linear-gradient(rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
  mask-repeat: no-repeat;
}
</style>
