<template lang="pug">
div(
  v-if='!showConsentWarning'
  :data-product-id='yotpoId'
  :data-name='yotpoName'
  :data-url='dataURL'
  class='yotpo yotpo-main-widget !w-full [&_.write-question-review-buttons-container_.write-question-review-button]:h-auto'
)
div(v-else class='w-full h-full flex items-center justify-center p-4 bg-gray-lighter')
  div(class='text-center')
    | Reviews are disabled because you haven't consented to non-essential cookies.
    br
    BaseButton(class='mt-8' label='Consent' to='KETCH' size='SMALL') Update Preferences
</template>

<script setup lang="ts">
const { $sitewideConfig } = useNuxtApp()
const { params } = useRoute()
const { showConsentWarning } = useYotpo()
const { yotpoId, yotpoName } = defineProps<{
  yotpoId: string
  yotpoName: string
}>()

const dataURL = computed(() => {
  return 'https://' + $sitewideConfig.domain + '/p/' + params.productLine
})
</script>
