<template lang="pug">
div(v-if='data' data-testid='productInfo')
  div(class='space-y-6')
    ProductHighlights(:data='data')
    div(v-if='data.hasOverriddenFeatureBullets' class='content')
      InlineHtml(v-if='data.features?.content' :text='data.features.content')
    div(v-if='data.orderBullets' class='content')
      ul(class='!mx-0 !my-4 list-inside')
        li(v-for='bullet in data.orderBullets' :key='bullet')
          InlineHtml(:text='bullet')
    ProductMessage(v-if='data.isPreOrder')
      template(#header) Product Available for Pre-order
      template(#text) Purchase today and the product will be shipped to you once it becomes available. #[b(v-if='data.preOrderDate') Estimated ship date: {{ formatDate }}]
      template(#disclaimer) Payment will be processed in full at time of purchase
    ClientOnly
      div
        template(v-for='drillKey in ocStore.optionDrillKeys' :key='drillKey')
          ProductDrill(:type='drillKey')

      ProductMessage(v-if='ocStore.hasOrderControlError' :show-error-icon='true')
      template(v-else)
        div(
          v-if='(ocStore.hasSplitters && !showRightSplitters) || (is3rdParty && !$sitewideConfig.config.nonTransactionalEnabled)'
        )
          div(@click='clickedChooseOptions')
            BaseButton(size='MEDIUM' :is-disabled='isChooseOptionsDisabled' class='w-full')
              template(v-if='!isProductPage') Choose Options
              template(v-else) Add to Cart

          div(class='mt-6')
            ProductDealerLocatorSingleDisplay(:data='data')
        template(v-else-if='!ocStore.hasSplitters')
          ProductSkuWarning(v-if='ocStore.hasMismatchedProduct' :should-flash='shouldFlashSkuWarning')
          template(v-else-if='data.isDealerOnly')
            DealerLocatorButton(:data='data')
          template(v-else)
            div(class='space-y-2')
              ProductAddToCart(
                v-if='!$sitewideConfig.config.nonTransactionalEnabled'
                ref='primaryAddToCart'
                :disabled='!isAddToCartEnabled'
                :is-out-of-stock='data.isOutOfStock'
                :sku='sku'
                :qty-selector='data.qtySelector'
                :add-fitment='!isProductPage'
                :is-pre-order='data.isPreOrder'
                @click='addToCartClicked'
              )
              StripePaymentRequestButtonProduct(
                v-if='!$sitewideConfig.config.nonTransactionalEnabled && isIntegrationEnabled("stripe") && $sitewideConfig.config.stripePaymentRequestButton && sku'
                :sku='sku'
              )
              ProductBackInStockNotification(
                v-if='$sitewideConfig.config.backInStockNotificationEnabled && isIntegrationEnabled("cognito") && data.isOutOfStock && !data.isDisabled'
                :product-line-slug='data.productLineSlug'
                :sku-slug='data.skuSlug'
              )

            ConstructorRecommendations(
              pod-id='product-page-right-rail-1'
              :item-id='data.productLineSlug'
              :override-padding='true'
              arrow-position='BOTTOM'
              arrow-width='FULL'
              class='pt-6'
            )
              template(#title='{ defaultTitle }')
                p(v-if='defaultTitle' class='text-base font-bold leading-none pb-6') {{ defaultTitle }}
          ProductDealerLocatorSingleDisplay(:data='data')
</template>
<script setup lang="ts">
const route = useRoute()
const ocStore = useOrderControlStore()
const { isIntegrationEnabled } = useUtils()
const { $sitewideConfig, $scrollTo } = useNuxtApp()

const { data } = defineProps<{ data: ProductLine }>()

const shouldFlashSkuWarning = ref(false)
const showRightSplitters = inject('showRightSplitters')
const is3rdParty = computed(() => ocStore.integration != null)
const sku = computed(() => {
  if (data.sku) return data.sku

  if (ocStore.quickProduct) {
    if (!isProductPage.value || (isProductPage.value && !ocStore.hasMismatchedProduct)) return ocStore.quickProduct?.sku
  }
})
const formatDate = computed(() => {
  if (!data || !data.preOrderDate) return ''

  return new Date(data.preOrderDate).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })
})

const isChooseOptionsDisabled = computed(() => {
  return ocStore.groupKeys.length === 0 && !is3rdParty.value
})

function clickedChooseOptions() {
  if (isChooseOptionsDisabled.value) {
    alertDrill()
    return
  }

  $scrollTo('#multiproducts-area', 500)
}

// even on sku pages, we have to wait for them to drill down to a part even though we show part info right off the bat (Thanks google)
const isAddToCartEnabled = computed(() => {
  // if there is a mismatch, don't let them add to cart
  if (ocStore.hasMismatchedProduct) return false

  if (data.isOutOfStock) return false

  // drilled down to 1 sku like normal
  if (ocStore.topProduct) return true
  // you are either on a product line page and found the 1 sku you'll end up auto-drilling to, or
  // you are on a product page and we found the 1 sku you'll auto-drill down to and it's the one that matches the page
  if (ocStore.quickProduct && !ocStore.hasMismatchedProduct) return true

  return false
})

const isProductPage = computed(() => {
  return !!route.params.skuSlug
})

function addToCartClicked() {
  if (ocStore.hasMismatchedProduct) {
    shouldFlashSkuWarning.value = true
    setTimeout(() => {
      shouldFlashSkuWarning.value = false
    }, 1000)
    return
  }
}
</script>
