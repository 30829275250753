<!-- eslint-disable vue/no-multiple-template-root -->
<template lang="pug">
template(v-if='data')
  Shipping(:data :show-icon @refresh='refresh')

  //- This is here so we can use the shipping data to show things.
  //- its a multi root node because where this component is used, because the parent spaces out the children
  slot(v-bind='data')
</template>

<script setup lang="ts">
const { product, showIcon } = defineProps<{
  product: ProductLine
  showIcon?: boolean
}>()

const { $geolocation, $uiEvents } = useNuxtApp()
const rootStore = useRootStore()
const urls = useUrls()

const productUrl = urls.getApiUrl('product')

// These are computed props so that when they change, the fetch is retriggered
const url = computed(() => `${productUrl}${product.productLineSlug}/${product.skuSlug}/shipping`)
const zipcode = computed(() => $geolocation.zipcode)

const { data, refresh } = useFetch<Shipping>(url, {
  method: 'POST',
  headers: {
    'x-perm-id': rootStore.originalPermId,
  },
  body: {
    zipcode: zipcode,
  },
  server: false,
})

watch(data, (newVal) => {
  $uiEvents.$emit('productShippingViewed', {
    product,
    shipping: newVal,
  })
})
</script>
