<!-- eslint-disable vue/no-v-html -->
<template lang="pug">
div(
  v-if='drill'
  data-testid='drillSelector'
  class='select mb-3'
  :style='{ display: drill.isHidden ? "none" : "block" }'
  :class='{ "is-disabled": drill.isDisabled }'
  @click='checkAlertDrill'
)
  select(
    :id='drill.id'
    v-model='drill.selectedValue'
    :disabled='drill.isDisabled'
    :aria-label='drill.options[0].text'
    class='capitalize disabled:pointer-events-none'
    :class='selectClass'
  )
    option(
      v-for='option in drill.options'
      :key='option.text'
      :value='option.slug'
      :disabled='option.isDisabled'
      v-html='option.text'
    )
</template>
<script setup lang="ts">
const ocStore = useOrderControlStore()
const props = defineProps<{
  type: DrillKey
  groupKey?: string
}>()

const drill = ocStore.getDrill(props.type, props.groupKey)

const selectClass = computed(() => {
  return {
    '-needsSelection': drill && drill.selectedValue == '-1' && !drill.isDisabled,
  }
})

function checkAlertDrill() {
  if (drill?.isDisabled) alertDrill(props.groupKey)
}
</script>
